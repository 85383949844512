import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// location and map plugins
import "leaflet/dist/leaflet.css";
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

///////////////////////////////////////////////////////
// VUE2 LEAFLET plugin specials
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

///////////////////////////////////////////////////////


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
