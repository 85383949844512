<template>

  <v-app id="app">

    
    <!-- Renders the navigation bar -->
    <core-app-bar />
    <!-- Vue component: https://router.vuejs.org/api/#router-view -->
    <router-view/>
  </v-app>
</template>


<script>
  export default {
    name: 'App',
    theme: {
      themes: {
        light: {
          primary: '#3f51b5',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          error: '#b71c1c'
        },
      },
    }, 
    components: {
      CoreAppBar: () => import('@/components/core/AppBar'), 
    },
    mounted() {
      this.$store.dispatch('getArticles'),
      this.$store.dispatch('getPictures')
      this.$store.dispatch('getLocations')
    }
  }
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@media screen and (min-width: 601px) {
  h2.responsive {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 600px) {
  h2.responsive {
    font-size: 35px !important;
  }
}
</style>
