import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    articles: [], //require('@/data/articles.json'),
    pictures: [],
    sortedArticles: [],
    locations: [],
    drawer: false,
    items: [
      {
        text: 'Home',
        href: '#!',
      },
      {
        text: 'About',
        href: '#about',
      },
    ],
     // fun state
    countX : 0
  },
  getters: {
    articleByID: (state) => (nID) => {
      return state.articles.find(a => a.key === nID)
    },
    allArticles: (state) => {
      return state.articles
    },
    articleLinkedPictures: (state) => (nID) => {
      let pictures = state.pictures.filter((pic) => pic.articleID == nID);pictures = pictures.sort((a, b) => {
        return a.order - b.order;
      });
      return pictures;
    },
    newestArticle: (state) => {
      if (state.articles) {
        var sortedArticles = state.articles;
        sortedArticles  = sortedArticles.sort((a,b) => {
          return new Date(a.publishDate) - new Date(b.publishDate);
        })
        return sortedArticles[sortedArticles.length - 1]
      } else {
        return false
      }
    },
    sortedArticles: (state)  => {
      var sortedArticles = state.articles;
      sortedArticles  = sortedArticles.sort((a,b) => {
        return new Date(b.date) - new Date(a.date);
      })
      //   if (a.date<b.date) {
      //     return -1
      //   } if (b.date < a.date) {
      //     return 1
      //   } 
      //   return 0
      // })
      return sortedArticles
    },
    // all articles, but sorted after date or publish date
    allArticlesSortedBy:
      (state) =>
      (sortCriteria = "date") => {
        var sortedArticles = state.articles;
        if (sortCriteria == "date") {
          sortedArticles = sortedArticles.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });
        } else if (sortCriteria == "date-") {
          sortedArticles = sortedArticles.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });
        } else if (sortCriteria == "publish") {
          sortedArticles = sortedArticles.sort((a, b) => {
            return new Date(a.publishDate) - new Date(b.publishDate);
          });
        } else if (sortCriteria == "publish-") {
          sortedArticles = sortedArticles.sort((a, b) => {
            return new Date(b.publishDate) - new Date(a.publishDate);
          });
        }

        return sortedArticles;
      },
    nextArticleID: (state,getters) => (key) => {
      let thisarticle = getters.articleByID(key)
      let currentIndex    = getters.sortedArticles.indexOf(thisarticle)
      let nextIndex = currentIndex++ +1
      let nextArticleID = null

      if (nextIndex < getters.sortedArticles.length) {
        nextArticleID = getters.sortedArticles[nextIndex].key;
      } else {
        nextArticleID = null;
      }
      return nextArticleID
    },

    previousArticleID: (state,getters) => (key) => {
      let thisarticle = getters.articleByID(key)
      let currentIndex    = getters.sortedArticles.indexOf(thisarticle)
      let previousIndex = currentIndex-- -1
      let previousArticleID = null

      if (previousIndex >= 0) {
        previousArticleID = getters.sortedArticles[previousIndex].key;
      } else {
        previousArticleID = null;
      }
      return previousArticleID
    },


    // fun function
    getcount: (state)=> {
      return state.countX
    },


    // Load subsets of lists
    articlesOfLocation: (state) => (locKey) => {
      let articles = state.articles.filter(art => art.locationID == locKey)
      return articles
    },
    picturesOfArticle: (state) => (artKey) => {
      let pictures = state.pictures.filter(pic => pic.articleID == artKey)
      return pictures
    },
    // picturesOfLocation: (state) => (locKey) => {
    //   let pictures = state.pictures.filter(pic => pic.locationID == locKey)
    //   return pictures
    // },
    allLocations: (state, getters) => {
      return state.locations.filter(l => getters.articlesOfLocation(l.key).length)
    },    
    locationByID: (state) => (key) => {
      let theLoc = state.locations.find(loc => loc.key == key)
      // console.log(theLoc)
      return theLoc
    },

    // MAP FUNCTIONS
    routePath: (state, getters) => {
      let path = []
      let sortedArticles = getters.sortedArticles
      // console.log('sortedArticles: '+ sortedArticles)
      


      // sortedArticles.sort((a, b) => {
      //   return new Date(a.date) - new Date(b.date);
      // });

      for (let a of sortedArticles) {
        // console.log('article' + a.key)
        let loc = getters.locationByID(a.locationID)
        if (loc) {
          // check if different from last point:
          if (loc != path[path.length-1]) {
            path.push([loc.lat, loc.lon])
          }
        }
      }
      // console.log(path)
      return path
    }

  },
  mutations: {
    // setDrawer: (state, payload) => (state.drawer = payload),
    // toggleDrawer: state => (state.drawer = !state.drawer),
    increment (state) {
      state.countX ++
    },
    storeArticles (state, payload) {
      state.articles = payload
    },
    storePictures (state, payload) {
      state.pictures = payload
    },
    storeLocations (state, payload) {
      state.locations = payload
    }
  },

  actions: {
    async getArticles (context) {
      let response = await fetch("https://h1q2i4.deta.dev/articles/")
      let data = await response.json()
      context.commit('storeArticles', data)
    },
    async getPictures (context) {
      let response = await fetch("https://h1q2i4.deta.dev/pictures/")
      let data = await response.json()
      context.commit('storePictures', data)
    },
    async getLocations (context) {
      let response = await fetch("https://h1q2i4.deta.dev/locations/")
      let data = await response.json()
      context.commit('storeLocations', data)
    }

  }
})

